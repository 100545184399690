// 新品上市
  <template>
  <el-row>
    <el-col :span="24">
        <div class="zone">
            <AdHeader :titmsg="titmsg" :arr="TabArr"></AdHeader>
            <GoodsList  v-if="ListFlag" :goodlist="goodList"></GoodsList>
        </div>
    </el-col>
   
  </el-row>
</template>

<style lang="less" scoped>
@import '~style/index.less';
</style>

<script>
// import {GetNewArrivalPage,GetIdNewArrivalPage} from "api/product.js";
import {SearchProducts} from "api/product.js";
const AdHeader=()=>import("components/index/AdHeader.vue");
const GoodsList=()=>import("components/index/GoodsList.vue");
import Ad from 'assets/hot.png';
export default {
    name:"NewGoods",
    data(){
        return {
             ListFlag:false,
            titmsg:'新品上市',
             TabArr:[],
            currentIndex:0,
            imgUrl: Ad,
            goodList:''//商品信息
        }
    },
     components:{
         AdHeader,
         GoodsList
    },
    created() {
        this.getNewGoods(true);
    },
    methods:{
        getNewGoods(){
            // // 新品上市接口
            // GetNewArrivalPage().then((data)=>{
            //     let datas={
            //         tagId:data.data.data[0].id
            //     }
            //     GetIdNewArrivalPage(datas).then((data)=>{
            //           this.goodList=data.data.data
            //           if(this.goodList.length>0){
            //             this.ListFlag=true
            //           }
            //     })
               
            // })
             // 新品上市接口
             let datas={
                    tagName: "新品上市",
                }
             SearchProducts(datas).then((data)=>{
                      this.goodList=data.data.data.records
                    //   console.log(this.goodList)
                      if(this.goodList.length>0){
                        this.ListFlag=true
                      }
                })
        }
    },
   
    
};
</script>
  
